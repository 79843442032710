<style scoped>
	.dialog_page_footer {
		text-align: right;
	}
	.community_box {
		max-height: 12rem;
		overflow-y: auto;
	}
</style>

<template>
	<div class="dialog_page">
		<div class="dialog_page_header" style="height: 20px;"></div>
		<div class="dialog_page_body">
			<el-form label-width="100px">
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="新的金额/元">
							<el-input placeholder="如果是免费请设置为0" type="" v-model="formData.new_money" />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="执行依据">
							<el-input placeholder="文字描述" type="textarea" v-model="formData.remark" />
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<div class="dialog_page_footer">
			<el-button @click.stop="clickCancel">取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click.stop="api_saveCommit">确定</el-button>
		</div>
	</div>
</template>

<script>
	import {
		sq_zq
	} from '../../../assets/js/config.js' //注意路径
	var _this;
	export default {
		props: ['pageParams'],
		data() {
			return {
				btnSaveLoading: false,
				formData: {
					new_money: '',
					remark: ''
				},
				options: [],
				bzOptions: []
			}
		},
		created: function() {
			_this = this;
			console.log(_this.pageParams)
		},
		mounted: function() {

		},
		methods: {


			// _ ** 点击取消
			clickCancel() {
				_this.$emit('childrenEvent', {
					type: 'close'
				});
			},

			// _ ** 添加保存
			api_saveCommit() {
				_this.btnSaveLoading = true;
				var params = _this.formData;
				params.cost_id = _this.pageParams.id;
				params.room_id = _this.pageParams.room_id;

				_this._postApi('/wy/costAdjustment/create', params).then((res) => {
					if (res.code == 1) {
						_this.btnSaveLoading = false;
						_this.$notify({
							title: '提示',
							message: "操作成功",
							type: 'success'
						});
						_this.$emit('childrenEvent', {
							type: 'success'
						});
					} else {
						_this.btnSaveLoading = false;
						_this.$notify({
							title: '提示',
							message: res.msg,
							type: 'warning'
						});
						// _this.$confirm(res.msg, '提示', {
						// 	confirmButtonText: '确定',
						// 	cancelButtonText: '取消',
						// 	type: 'warning'
						// }).then(() => {

						// }).catch(() => {

						// });
					}
				}).catch((err) => {

				})
			}
		}
	}
</script>
