<style scoped>
	.tpm_box {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.tem_body {
		flex: 1;
		width: 100%;
	}
	.tem_header {
		padding: 5px;
		display: flex;
		align-items: center;
	}
	.tem_body_contair {
		height: 100%;
		width: 100%;
	}
	.tem_footer {
		text-align: right;
		padding: 5px;
	}
	.mb {
		padding: 0 20px 0 10px;
	}
	/* 弹窗 */
	.el-dialog__wrapper {
		position: absolute;
		left: 0;
		top: 0;
		display: flex;
		align-items: center;
		overflow: hidden;
	}
	.el-dialog.my_dialog {
		margin-top: 0;
	}
	/* 房间信息 */
	.room_info {
		padding: 0;
	}
	button.el-button.sear-bb.el-button--default {
		background-color: #13ce66;
		color: #fff;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.mb span {
		font-size: 1.2rem;
	}
</style>
<style>
	table {
		box-sizing: border-box;
	}
	/* 用来设置当前页面element全局table 选中某行时的背景色*/
	.el-table__body tr.current-row>td {
		background-color: #fff !important;
		color: #333;
	}
	.mb .el-breadcrumb__inner {
		font-weight: bold !important;
		font-size: 1.1rem;
	}
	.el-table__expanded-cell[class*='cell'] {
		padding: 5px;
		background-color: rgb(175, 246, 192);
	}
	.el-table .el-table__expanded-cell:hover {
		background-color: rgb(175, 246, 192) !important;
	}
	.el-table .tableRowClassName {
		background: oldlace;
	}
	.my_dialog.ml .el-dialog__header {
		padding: 0 !important;
	}
</style>
<template>
	<div class="tpm_box">
		<div class="tem_header" style="padding: 10px 0;">
			<div class="mb">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<span>已选择：</span>
					<span>{{ buildPageParams.community.name }}</span>
				</el-breadcrumb>
			</div>

			<!-- <el-button type="danger" size="small">
				刷新
				<i class="el-icon-refresh-right"></i>
			</el-button> -->

			<div class="header_item" style="margin-left: 20px;">
				<el-input placeholder="请输入房屋唯一编号" v-model="room_code" size="small" class="input-with-select">
					<el-button slot="append" icon="el-icon-search" size="small" class="sear-bb" @click="goSerch()">搜索
					</el-button>
				</el-input>
			</div>
			<div class="header_item" style="margin-right: 20px;">
				<el-button size="small" type="warning" style="margin-left: 10px;" @click="openJiLu()">操作记录
				</el-button>
			</div>
		</div>
		<div class="room_info">
			<!-- {{buildPageParams}} -->
			<span v-if="room_code == ''" style="font-size: 1.6rem;color: red;">请选择房屋或者搜索房屋</span>
		</div>

		<div class="tem_body" id="tem_body">
			<div class="tem_body_contair" id="table_box" v-loading="tableLoading" v-if="!tableLoading">
				<el-table :data="tableData" :height="tableHeight" border row-key="id">
					<el-table-column prop="charge_month" label="月份" width="">
						<template slot-scope="scope">
							{{ scope.row.charge_month }}
							<span v-if="scope.row.pay_status" style="color: red;font-weight: bold;"> &nbsp; &nbsp;
								({{ scope.row.pay_status }})</span>
						</template>
					</el-table-column>
					<el-table-column prop="ys_total" label="调整前应收/元" width=""></el-table-column>
					<el-table-column prop="ys_total" label="调整后应收/元" width=""></el-table-column>
					<el-table-column prop="item_name" label="" width="260">
						<template slot-scope="scope">
							<el-popover placement="right" width="500" trigger="click">
								<div class="info_pop">
									<el-table :data="scope.row.list" style="width: 100%">
										<el-table-column prop="item_name" label="项目"></el-table-column>
										<el-table-column prop="pay_status_text" label="状态"></el-table-column>
										<el-table-column prop="ys_fy" label="应收费用/元"></el-table-column>
										<el-table-column prop="ys_fy" label="操作">
											<template slot-scope="scope1">
												<el-button type="text"><span @click="openTianzheng(scope1.row)">调整</span></el-button>
												<el-button type="text"><span style="color: red;"
														@click="deleteItem(scope1.row)">删除</span></el-button>
											</template>
										</el-table-column>
									</el-table>
								</div>
								<el-button type="text" slot="reference">操作</el-button>
							</el-popover>
							
							<!-- <el-button type="text" slot="reference" @click="openTianzheng(scope.row)">调整</el-button>
							<el-button type="text" style="color:red;" slot="reference">删除</el-button> -->
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div class="tem_footer">
			<!-- <el-pagination @current-change="handleCurrentChange" :current-page="page_num" :page-sizes="[20]"
				:page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
			</el-pagination> -->
		</div>


		<el-dialog custom-class="my_dialog ml" width="30%" top="none" :show-close="false" :visible.sync="dialog.tianzheng" destroy-on-close>
			<tianzheng v-if="dialog.tianzheng" :pageParams="dialog.tianzhengParam" v-on:childrenEvent="tianzhengEvent"></tianzheng>
		</el-dialog>
		
		<!-- 操作记录 -->
		<el-dialog custom-class="my_dialog ml" width="80%" top="none" :show-close="false" :visible.sync="dialog.jilu" destroy-on-close>
			<jilu v-if="dialog.jilu" :pageParams="dialog.jiluParam" v-on:childrenEvent="jiluEvent"></jilu>
		</el-dialog>
		
	</div>
</template>

<script>
	import tianzheng from './dialog/tiaozheng.vue';
	import jilu from './dialog/jilu.vue';
	var _this;
	export default {
		components: {
			tianzheng: tianzheng,
			jilu:jilu
		},
		props: ['buildPageParams'],
		data() {
			return {
				tableHeight: 0,
				tableLoading: false,
				tableData: [],
				pageTotal: 0,
				pageSize: 20,
				page_num: 1,
				room_code: '',
				ttt: '',
				dialog: {
					tianzheng: false,
					tianzhengParam: null,
					jilu:false,
					jiluParam:null
				}
			};
		},
		created: function() {
			_this = this;
			if (_this.buildPageParams.room) {
				_this.room_code = _this.buildPageParams.room.code;
				_this.getColletList();
			}
		},
		mounted: function() {
			_this.$nextTick(function() {
				_this.tableHeight = document.getElementById('tem_body').offsetHeight;
			});
		},
		methods: {
			/**
			 * 搜索
			 * @author:郑凯
			 * @create_time:2021-3-18 11:40:10
			 */
			goSerch() {
				_this.page_num = 1;
				_this.getColletList();
			},
			/** --------> 方法介绍: 切换分页
				author:郑凯 创建时间:2021-1-25 13:43:40 */
			handleCurrentChange(val) {
				_this.page_num = val;
				_this.getColletList();
			},

			/**
			 * 获取收费列表
			 * @author:郑凯
			 * @create_time:2021-3-18 11:33:22
			 */
			getColletList() {
				_this.tableLoading = true;
				_this
					._postApi('/wy/costAdjustment/getListByRoom', {
						residence_id: _this.buildPageParams.community.id,
						room_code: _this.room_code,
						page: _this.page_num,
						limit: 10000,
					})
					.then(function(res) {
						//console.log(res);
						_this.tableData = res.data.list;
						_this.tableLoading = false;
					})
					.catch(function() {});
			},

			/**   
			 * @discribe 调整
			 * @author:zk 
			 * @create-time:2021-8-5 12:12:00 ?F10: PM? 
			 */
			openTianzheng(data) {
				_this.dialog.tianzhengParam = data;
				_this.dialog.tianzheng = true;
			},
			tianzhengEvent() {
				_this.dialog.tianzheng = false;
			},
			
			/**
			 * @discribe 操作记录
			 * @author:zk 
			 * @create-time:2021-8-5 12:12:00 ?F10: PM? 
			 */
			openJiLu() {
				console.log(_this.buildPageParams)
				_this.dialog.jiluParam = {
					community:_this.buildPageParams.community,
					residence_id: _this.buildPageParams.community.id
				}
				_this.dialog.jilu = true;
			},
			jiluEvent() {
				_this.dialog.jilu = false;
			},

			/**
			 *
			 * @author:郑凯
			 * @create_time:2021-3-19 16:00:32
			 */
			deleteItem(obj) {
				const h = this.$createElement;
				this.$msgbox({
					title: '消息',
					type: "warning",
					message: h('p', null, [h('span', null, '确定删除项目 '), h('span', {
						style: 'color: teal'
					}, obj.item_name)]),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '执行中...';

							_this
								._postApi('/wy/charge/itemDel', {
									cost_id: obj.id,
									room_id: _this.tableData[0].room_id
								})
								.then(function(res) {
									done();
									_this.goSerch();
									setTimeout(() => {
										instance.confirmButtonLoading = false;
									}, 300);
								})
								.catch(function() {});
						} else {
							done();
						}
					}
				}).then(action => {

				});
			},



			/**   
			 * @ 票据打印
			 * @author:郑凯 
			 * @create_time:2021-3-22 10:41:54 
			 */
			openBillPrinting() {
				// console.log(_this.buildPageParams)
				if (!_this.buildPageParams.room) {
					_this
						.$confirm('请先选择房间或者搜索房间', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						})
						.then(() => {})
						.catch(() => {});
					return;
				}
				var dialogOfflineChargingParam = {};
				// dialogOfflineChargingParam.room_id = _this.tableData[0].room_id;
				// dialogOfflineChargingParam.room_text = _this.tableData[0].room_text;
				dialogOfflineChargingParam.room_id = _this.buildPageParams.room.id;
				dialogOfflineChargingParam.room_text = _this.buildPageParams.room.name;
				_this.dialogOfflineChargingParam = dialogOfflineChargingParam;
				_this.dialogBillPrinting = true;
			},
			billPrintingEvent(e) {
				if (e.type == 0) {
					_this.dialogBillPrinting = false;
				}
			},


			dialod_costYJDownCondition_event(e) {
				if (e.type == 1) {
					_this._axios({
						method: 'get',
						params: e.params,
						url: '/wy/charge/costYJDown',
						headers: {
							"token": localStorage.getItem('token')
						},
						responseType: 'arraybuffer'
					}).then((response) => {
						// console.log(response.getResponseHeaders('content-type'));
						//console.log(response)
						let blob = new Blob([response.data], {
							type: 'application/vnd.ms-excel;charset-UTF-8'
							// type: 'application/zip'
						})
						let downloadElement = document.createElement('a');
						let href = window.URL.createObjectURL(blob); //创建下载的链接
						downloadElement.href = href;
						downloadElement.download = _this.buildPageParams.community.name + e.params.start_month + '至' + e.params.end_month + '已缴费.xlsx'; //下载后文件名
						document.body.appendChild(downloadElement);
						downloadElement.click(); //点击下载
						document.body.removeChild(downloadElement); //下载完成移除元素
						window.URL.revokeObjectURL(href); //释放掉blob对象
					}).catch(() => {

					});
				} else {
					_this.dialod_costYJDownCondition = false;
				}
			}
		}
	};
</script>
