<style scoped>
	.tpm_box{width: 100%;height: 100%;display: flex;flex-direction: column;}
	.tem_body{flex: 1;width: 100%;}
	.tem_header{padding: 5px;display: flex;align-items: center;};
	.tem_body_contair{height: 100%;width: 100%;}
	.tem_footer{text-align: right;padding: 5px;}
	.mb{padding: 0 20px 0 10px;}
	/* 弹窗 */
	.el-dialog__wrapper{position: absolute;left: 0;top: 0;display: flex;align-items: center;overflow: hidden;}
	.el-dialog.my_dialog{margin-top: 0;}
</style>
<style>
	table{box-sizing: border-box;}
	/* 用来设置当前页面element全局table 选中某行时的背景色*/
	.el-table__body tr.current-row>td{
	  background-color: #67CD80 !important;
	  color: #fff;
	}
	.mb .el-breadcrumb__inner{font-weight: bold!important;font-size: 1.1rem;}
	.el-table__expanded-cell[class*=cell]{padding: 5px;background-color: rgb(175,246,192);}
	.el-table .el-table__expanded-cell:hover{background-color: rgb(175,246,192)!important;}
	.el-table .tableRowClassName{background: oldlace;}
</style>
<template>
	<div class="tpm_box">
		<div class="tem_header" style="padding: 10px 0;">
			<div class="mb">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item>{{pageParams.community.name}}</el-breadcrumb-item>
					<el-breadcrumb-item v-if="pageParams.build">{{pageParams.build.name}}</el-breadcrumb-item>
					<el-breadcrumb-item v-if="pageParams.unit">{{pageParams.unit.name}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			
			<el-button type="danger" size="small" @click="thisReload">刷新<i class="el-icon-refresh-right"></i></el-button>
			
			<div class="header_item" style="margin-left: 20px;">
				<el-select size="small" v-model="s_year" placeholder="选择年份查看" @change="statusSelectYear">
					<el-option label="全部" value="''">全部</el-option>
					<el-option :label="item" :value="item" v-for="item in yearOptions">{{item}}</el-option>
				</el-select>
			</div>
			<div class="header_item" style="margin-left: 20px;">
				<el-select size="small" v-model="s_mouth" placeholder="选择月份查看" @change="statusSelectYear">
					<el-option label="全部" value="''">全部</el-option>
					<el-option :label="item" :value="item" v-for="item in mouthOptions">{{item}}</el-option>
				</el-select>
			</div>
		</div>
		
		<div class="tem_body" id="tem_body">
			<div class="tem_body_contair" id="table_box" v-loading="tableLoading" v-if="!tableLoading">
				<el-table :data="tableData" :height="tableHeight" border @row-click="rowClick" highlight-current-row row-key="id"
				 @expand-change="expandChange">
					
				</el-table>
			</div>
		</div>
		<div class="tem_footer">
			<el-pagination @current-change="handleCurrentChange" :current-page="pageNumber" :page-sizes="[20]" :page-size="pageSize"
			 layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
			</el-pagination>
		</div>


	</div>
</template>

<script>
	var _this;
	export default {
		components: {

		},
		props: ['buildPageParams'],
		data() {
			return {
				communityId: 0,
				pageParams: null,
				tableLoading: true,
				tableHeight: 0,
				tableData: [],
				pageNumber: 1,
				pageSize: 20,
				pageTotal: 0,
				selectTableRow: null, // 选中的表格某行
				bindStandardDialog: false,
				dialogPageParams: {},
				bindPageTitle: '',
				yearOptions:[],
				mouthOptions:[],
				s_year:'',
				s_mouth:''
			}
		},
		created: function() {
			_this = this;
			_this.communityId = _this.buildPageParams.community.id;
			_this.pageParams = _this.buildPageParams;
			_this.apiGetSelectOption();
		},
		mounted: function() {
			_this.$nextTick(function() {
				_this.tableHeight = document.getElementById("tem_body").offsetHeight;
				_this.api_getTableData();
			})
		},
		methods: {
			/** --------> 方法介绍: 刷新
			author:郑凯 创建时间:2021-1-25 13:43:30 */
			thisReload() {
				_this.selectTableRow = null;
				_this.tableHeight = document.getElementById("tem_body").offsetHeight;
				_this.api_getTableData();
			},
			/**   
			 * 获取查询条件
			 * author:郑凯 
			 * create_time:2021-3-10 11:09:28 
			 */
			apiGetSelectOption() {
				var requstParam = {};
				console.log(_this.pageParams)
				if(_this.pageParams.community){
					requstParam.residence_id = _this.pageParams.community.id;
				}
				if(_this.pageParams.build){
					requstParam.build_id = _this.pageParams.build.id;
				}
				if(_this.pageParams.unit){
					requstParam.unit_id = _this.pageParams.unit.id;
				}
				
				_this._postApi('/wy/costList/getYearMonth', requstParam)
				.then(function(res) {
					console.log(res)
					_this.yearOptions = res.data.year;
					_this.mouthOptions =  res.data.month;
				}).catch(function() {

				})
			},

			/**   
			* 选择年
			* author:郑凯 
			* create_time:2021-3-10 12:08:32 
			*/
			statusSelectYear(){
				
			},
			
			/** --------> 方法介绍: 切换分页
			author:郑凯 创建时间:2021-1-25 13:43:40 */
			handleCurrentChange(val) {
				_this.pageNumber = val;
				_this.api_getTableData();
			},
			
			/** --------> 方法介绍: 获取表格数据
			author:郑凯 创建时间:2021-1-25 13:43:53 */
			api_getTableData() {
				_this.tableLoading = true;
				_this._postApi('/wy/chargeStandardSet/getList', {
					residence_id: _this.communityId,
					page: _this.pageNumber,
					limit: _this.pageSize
				}).then((res) => {
					if (res.code == 1) {
						var baseData = res.data.list;
						_this.pageTotal = res.data.count;
						baseData.forEach(function(item) {

						})
						_this.tableData = baseData;
						_this.tableLoading = false;
					} else {

					}
				}).catch((err) => {});
			},

			/** --------> 方法介绍: 选择某行
			author:郑凯 创建时间:2021-1-25 13:44:20 */
			rowClick(row, column, event) {
				_this.selectTableRow = row;
			},

			// _ *** 上传弹框事件消息
			bindStandardEvent(e) {
				switch (e.type) {
					case 'close':
						_this.bindStandardDialog = false;
						break;
					case 'success':
						_this.thisReload();
						_this.$emit('childrenEvent', {
							type: 'add'
						});
						_this.bindStandardDialog = false;
						break;
				}
			},

			/** --------> 方法介绍: 绑定收费标准
			author:郑凯 创建时间:2021-1-25 13:46:17 */
			bindBtn() {
				_this.dialogPageParams.housingEstate = _this.buildPageParams;
				_this.dialogPageParams.upPage = 'community';
				_this.bindPageTitle = _this.buildPageParams.name + '    绑定/变更收费标准';
				_this.bindStandardDialog = true;
			},
			/** --------> 方法介绍: 展开行监听
			author:郑凯 创建时间:2021-1-26 16:05:02 */
			expandChange(row, expandedRows) {
				if (!row.children) {
					// 通过$set属性可设置loading实现实时加载loading效果(经过测试,通过$set直接给父层数据声明子层数据时会出现报错,所以才在获取父层表格数据时声明子层表格数据)
					_this.$set(row, 'loading', true);
					_this._getApi('/wy/chargeStandard/getItemList', {
						standard_id: row.standard_id
					}).then((res) => {
						if (res.code == 1) {
							var baseData = res.data;
							_this.tableData.forEach(function(item, index) {
								if (item.id === row.id) {
									_this.tableData[index].childrenData = baseData || [];
								}
							});
							_this.$set(row, 'loading', false);
						} else {
							_this.$set(row, 'loading', false);
						}
					}).catch((err) => {});
				}
			}
		}
	}
</script>
