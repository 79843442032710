import { render, staticRenderFns } from "./tiaozheng.vue?vue&type=template&id=286e4afb&scoped=true&"
import script from "./tiaozheng.vue?vue&type=script&lang=js&"
export * from "./tiaozheng.vue?vue&type=script&lang=js&"
import style0 from "./tiaozheng.vue?vue&type=style&index=0&id=286e4afb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "286e4afb",
  null
  
)

export default component.exports